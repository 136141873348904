const numberFormats = {
  en: {
    currency: { style: "currency", currency: "USD" }
  },
  hu: {
    currency: { style: "currency", currency: "Ft" }
  },
}

export default numberFormats
